import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

Swiper.use([Autoplay, EffectFade, Pagination, Navigation]);

// Components


// Global
window.Swiper = Swiper;
window.Alpine = Alpine;

// gsap
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.ScrollToPlugin = ScrollToPlugin;

// Inits
Alpine.plugin(collapse);
Alpine.start();